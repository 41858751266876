.about {

  .about-box {
    margin: 40px 0;

    @include media-breakpoint-down(md) {
      text-align: center;
    }

    h2 {
      color: $black-color;
      font-size: 18px;
      margin-bottom: 20px;
    }

    p {
      max-width: 580px;

      @include media-breakpoint-down(md) {
        max-width: 460px;
        margin: 0 auto;
      }
    }
  }

  .main-button {
    @include media-breakpoint-down(md) {
      margin: 0 auto;
      display: block;
    }
  }

  .about-img-one, .about-img-second {
    width: auto;
    max-width: 100%;
    margin-top: 50px;
    height: 300px;
    @include border-radius(12px);

    @include media-breakpoint-down(md) {
      &.about-img-one {
        display: block;
        margin: 50px auto 0;
      }

      &.about-img-second {
        display: none;
      }
    }
  }
}