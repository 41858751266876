.mobile-menu {
  padding: 20px;

  .mobile-logo-img {
    height: 42px;
  }

  .close-menu {
    position: absolute;
    top: 32px;
    right: 20px;
    border: none;
    background: transparent;

    img {
      width: 20px;
    }
  }

  .mobile-menu-links {
    margin: 60px auto 40px;
    text-align: center;

    a {
      color: $black-color;
      display: block;
      margin: 24px 0;
      padding: 0;
      font-size: 14px;
      font-weight: 500;

      &.active {
        color: $accent-color;
      }
    }
  }

  .mobile-phone-btn {
    width: 180px;
    display: block;
    padding: 0;
    margin: 0 auto;

    &:hover {
      color: $accent-color;
    }

    img {
      position: relative;
      top: 1px;
      margin-right: 2px;
    }
  }
}