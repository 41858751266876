.private-policy-section {

  @include media-breakpoint-down(md) {
    padding: 50px 0 0;
  }

  .head-box {
    text-align: center;
    margin-bottom: 60px;

    h1 {
      color: $black-color;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }
  }

  .container {
    max-width: 960px;
  }

  .private-policy-content {
    background: $white-color;
    border: 1px solid $light-color;
    width: 100%;
    padding: 60px 80px 40px;
    @include border-radius(12px);

    @include media-breakpoint-down(md) {
      padding: 40px 40px 20px;
    }

    @include media-breakpoint-down(sm) {
      padding: 40px 20px 20px;
    }
  }

  .private-policy-box {
    margin-bottom: 40px;

    .divider-img {
      width: 70px;
      margin: 10px 0;
    }

    h2 {
      color: $black-color;
      font-size: 18px;
      font-weight: 600;

      @include media-breakpoint-down(sm) {
        font-size: 14px;
      }

      span {
        color: $accent-color;
      }
    }

    a {
      color: $accent-color;

      &:hover {
        color: lighten($black-color,20%);
      }
    }

    ul {
      list-style-type: none;
      margin: 15px 0 15px 60px;

      li {
        color: lighten($black-color,20%);
        font-size: 12px;
        margin: 10px 0;
        font-weight: 500;
        position: relative;
        line-height: 22px;

        &:before {
          content: '▪';
          color: $accent-color;
          position: absolute;
          left: -15px;
          top: 1px;
        }
      }
    }

    ol {
      margin: 20px 0 20px 40px;

      li {
        font-size: 12px;
        color: $black-color;

        &:before {
          content: '-';
        }
      }
    }

    strong {
      font-weight: 600;
    }

    p {

      span.number {
        color: $accent-color;
      }

      &.top {
        padding: 15px 0 15px 20px;
      }
    }

    .less-btn {
      background: transparent;
      border: none;
      color: $black-color;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
      padding: 0;
      margin-top: 20px;
      margin-left: 20px;
      text-transform: none;

      &:hover {
        opacity: 0.8;
      }

      img {
        margin-right: 6px;
        position: relative;
        width: 20px;
        top: 5px;
      }
    }

    .less {
      display: none;
      margin: 0;
      padding: 0;
    }
  }
}