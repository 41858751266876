.error-site {

  .error-logo {
    display: block;
    margin: 60px auto 40px;
    width: 100%;
    max-width: 180px;

    @include media-breakpoint-down(sm) {
      max-width: 120px;
    }
  }

  .desc {
    max-width: 420px;
    margin: 0 auto 40px;
    text-align: center;
  }

  .main-button {
    display: block;
    margin: 0 auto;
  }
}