nav {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.desktop-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 760px;
  margin-top: 10px;

  @include media-breakpoint-down(md) {
    display: none;
  }

  a {
    color: $grey-color;
    font-weight: 500;
    font-size: 14px;
    @include variable-transition(.3s);

    &:hover {
      color: $black-color;
    }

    &.active {
      color: $accent-color;
    }
  }
}