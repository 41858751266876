.contact-site {

  .small-divider {
    margin: 0 0 8px;

    @include media-breakpoint-down(md) {
      margin: 0 auto 8px;
    }
  }

  .right-side-content, .left-side-content {
    margin-top: 50px;
  }

  h2 {
    color: $black-color;
    font-size: 18px;
    font-weight: 600;

    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }

  input[type="text"],
  input[type="email"],
  textarea {
    max-width: 100%;
    font-size: 13px;
  }

  form {
    margin-top: 40px;
    max-width: 700px;

    @include media-breakpoint-down(md) {
      margin: 40px auto 0;
    }
  }

  .success-contact-form {
    margin-top: 40px;
    max-width: 700px;
    border: 1px solid $light-color;
    background: $white-color;
    padding: 100px 40px;
    @include border-radius(12px);

    @include media-breakpoint-down(md) {
      margin: 40px auto 0;
    }

    .contact-logo {
      display: block;
      margin: 0 auto 30px;
      width: 100%;
      max-width: 180px;
    }

    p {
      max-width: 360px;
      text-align: center;
      margin: 0 auto;
    }

    .main-button {
      display: block;
      margin: 40px auto 0;
    }
  }

  .main-button {
    @include media-breakpoint-down(md) {
      margin: 0 auto;
    }
  }

  .info {
    font-size: 12px;
    margin: 20px 0;

    @include media-breakpoint-down(md) {
      text-align: center;
    }

    span {
      color: $accent-color;
    }

    a {
      color: $accent-color;

      &:hover {
        color: $black-color;
      }
    }
  }

  .contact-box {
    margin-top: 40px;

    a {
      color: $grey-color;
      @include variable-transition(.3s);

      @include media-breakpoint-down(md) {
        text-align: center;
      }

      &:hover {
        color: $black-color;
      }

      &.phone {
        font-size: 26px;
        display: block;
        font-weight: 600;
        margin-bottom: 30px;
        color: $black-color;

        &:hover {
          color: $accent-color;
        }
      }
    }

    .contact-details {
      @include media-breakpoint-down(md) {
        text-align: center;
      }

      p {
        margin: 12px 0;
        color: $grey-color;

        @include media-breakpoint-down(md) {
          display: table;
          margin: 12px auto;
        }
      }

      img {
        float: left;
        margin-right: 10px;
        position: relative;
        top: 3px;

        &.second-img {
          margin-left: 4px;
          margin-right: 14px;
        }
      }

      span {
        float: left;
      }
    }
  }

  #google-container {
    position: relative;
    width: 100%;
    height: 350px;
    margin-top: 60px;
    @include border-radius(15px);
    @include box-shadow-custom($light-color);
  }

  #cd-google-map {
    position: relative;

    address {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      padding: 1em 1em;
      color: $white-color;
      background: $accent-color;
      opacity: 0.9;
      text-align: center;
      @include border-radius(15px);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}