.realizations {

  &.home {
    background: #FBFBFB;
  }

  .realization-content, .img-row {
    margin-top: 50px;
  }

  .realization-img-one, .realization-img-second {
    width: auto;
    max-width: 100%;
    height: 300px;
    @include border-radius(12px);

    @include media-breakpoint-down(md) {
      display: block;
      margin: 0 auto;

      &.about-img-second {
        display: none;
      }
    }
  }

  .realization-img-third {
    display: block;
    width: 100%;
    margin-top: 30px;
    @include border-radius(12px);

    @include media-breakpoint-down(md) {
      display: block;
      margin: 40px auto 0;
      max-width: 320px;
    }
  }

  .realization-box {
    display: flex;
    justify-content: space-between;
    max-width: 520px;
    margin: 0 0 40px;

    .number {
      min-width: 36px;
      height: 34px;
      display: block;
      background: $accent-color;
      margin-right: 20px;
      position: relative;
      text-align: center;
      font-weight: 600;
      font-size: 18px;
      line-height: 36px;
      color: $white-color;
      @include border-radius(6px);
    }

    h2 {
      font-size: 18px;
      color: $black-color;
      margin-top: 4px;
      margin-bottom: 20px;
    }

    p {
      color: $grey-color;
      font-size: 13px;
    }

    @include media-breakpoint-down(md) {
      display: block;
      margin: 0 auto 40px;

      .number {
        width: 38px;
        min-width: 32px;
        height: 30px;
        display: table;
        margin: 0 auto 12px;
        line-height: 32px;

        img {
          width: 14px;
        }
      }

      h2, p { text-align: center }
    }
  }
}