.main-section {
  background-image: url('../../dist/img/bg/main-bg.jpg');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 250px 0;
  margin-top: -100px;
  position: relative;

  @include media-breakpoint-down(md) {
    padding: 150px 0 50px;
  }

  .scroll-down {
    display: block;
    margin: 0 auto;
    position: absolute;
    bottom: 5%;
    left: 0;
    right: 0;
    background: transparent;
    border: none;
    @include basic-transition;

    &:hover {
      opacity: 0.8;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .panel-img {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
  }

  .flex-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .home-left {
    h1 {
      text-align: left;
      font-weight: 700;
      font-size: 42px;
      line-height: 48px;
      margin-bottom: 20px;

      @include media-breakpoint-down(md) {
        text-align: center;
      }

      @include media-breakpoint-down(xs) {
        font-size: 32px;
        line-height: 38px;
      }
    }

    h2 {
      font-size: 24px;
      font-weight: 700;
      color: $black-color;
      max-width: 520px;

      @include media-breakpoint-down(md) {
        text-align: center;
        margin: 0 auto;
      }

      @include media-breakpoint-down(xs) {
        font-size: 20px;
        margin: 0 auto;
      }

      span {
        color: $accent-color;
        font-weight: 700;
        margin-left: 6px;

        @include media-breakpoint-down(xs) {
          display: block;
          margin-left: 0;
        }
      }
    }

    p {
      font-weight: 500;
      color: $grey-color;
      line-height: 22px;
      text-align: left;
      max-width: 480px;
      font-size: 14px;
      margin-top: 20px;

      @include media-breakpoint-down(md) {
        text-align: center;
        margin: 20px auto 0;
      }

      @include media-breakpoint-down(xs) {
        max-width: 320px;
      }
    }

    .flex-btns {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 60px;
      max-width: 380px;

      .white {
        background: transparent;
      }

      @include media-breakpoint-down(md) {
        margin: 60px auto 0;
      }

      @include media-breakpoint-down(xs) {
        display: block;
        margin: 40px auto 0;

        .main-button {
          display: block;
          margin: 15px auto;
        }
      }
    }
  }
}