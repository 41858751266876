body {
  background-color: $white-color;
  font-size: $font-size;
  font-weight: 400;
  color: $grey-color;
  font-family: $font-family-one;
}

.wow {
  visibility: hidden;
}

a,button {
  color: $grey-color;
  outline: none !important;
  text-decoration: none;
  font-weight: 500;
  @include basic-transition;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}

section {
  padding: 80px 0;

  @include media-breakpoint-down(sm) {
    padding: 40px 0;
  }
}

.top-divider {
  height: 4px;
  width: 38px;
  display: block;
  margin: 0 auto 12px;
  background: $accent-color;
  @include border-radius(6px);

  &.small-divider {
    height: 3px;
    width: 22px;
  }
}

h1 {
  font-size: 28px;
  line-height: 38px;
  font-weight: 600;
  color: $black-color;
  text-align: center;

  @include media-breakpoint-down(sm) {
     font-size: 24px;
    line-height: 32px;
  }
}

h2 {
  font-size: 22px;
  line-height: 26px;
  font-weight: 600;
}

h3 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
}

p {
  font-size: 13px;
  line-height: 20px;
  color: $grey-color;
  font-weight: 500;
}

.container {
  max-width: 1280px;
  padding: 0 40px;

  @include media-breakpoint-down(sm) {
    padding: 0 20px;
  }
}