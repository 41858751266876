label {
  display: block;
  font-size: 12px;
  color: $black-color;
  font-weight: 500;
  margin-bottom: 8px;

  span {
    color: $accent-color;
  }
}

input {
  &[type="text"],
  &[type="email"],
  &[type="tel"],
  &[type="password"] {
    display: block;
    width: 100%;
    height: 47px;
    max-width: 320px;
    border: 1px solid $light-color;
    background: $white-color;
    padding: 0 15px;
    outline: none;
    color: $grey-color;
    font-size: 14px;
    margin-bottom: 20px;
    @include border-radius(6px);
  }
}

textarea {
  display: block;
  width: 100%;
  height: 47px;
  border: 1px solid $light-color;
  background: $white-color;
  padding: 15px;
  outline: none;
  color: $grey-color;
  font-size: 14px;
  resize: none;
  min-height: 120px;
  @include border-radius(6px);
}