@mixin left-right-hover {
  &:before {
    content:'';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 0px;
    height: 100%;
    background: rgba(255,255,255,0.3);
    transition: all 0.5s ease;
  }

  &:hover{
    &:before {
      width: 100%;
    }
  }
}

@mixin left-right-red-hover {
  &:before {
    content:'';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 0px;
    height: 100%;
    background: rgba(255,75,46,0.3);
    transition: all 0.5s ease;
  }

  &:hover{
    &:before {
      width: 100%;
    }
  }
}

@mixin placeholder-color($color) {
  &::-webkit-input-placeholder { /* WebKit browsers */
    color: $color;
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $color;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $color;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10+ */
    color: $color;
  }
}

@mixin input-filled($color, $font-color) {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active  {
    -webkit-text-fill-color: $font-color !important;
    -webkit-box-shadow: 0 0 0 30px $color inset !important;
  }
}
