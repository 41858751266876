.realization-site {
  padding: 50px 0;

  .realizations-box {
    padding: 50px 0;

    .small-divider {
      margin: 0 0 8px;
    }

    h2 {
      color: $black-color;
      margin-bottom: 25px;
    }
  }
}