header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100px;
  transition: background-color .3s ease-in-out;

  @include media-breakpoint-down(md) {
    height: 80px;
  }

  &.page-header {
    border-bottom: 1px solid $light-color;
    background: rgba(255,255,255,0.9);
  }

  &.scrolled {
    border-bottom: 1px solid $light-color;
    background: rgba(255,255,255,0.9);
  }

  .container, .row {
    height: 100%;
  }

  .flex-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;

    .logo-img {
      height: 52px;
      @include basic-transition;

      @include media-breakpoint-down(md) {
        height: 42px;
      }
    }
  }

  .mobile-menu-btn {
    display: none;

    @include media-breakpoint-down(md) {
      display: block;
      height: 40px;
      width: 40px;
      border: none;
      background: transparent;

      img {
        height: 20px;
      }
    }

    &:hover {
      opacity: 0.8;
    }
  }
}