.breadcrumbs {
  background: transparent;
  padding-top: 20px;
  padding-bottom: 0;

  .breadcrumbs-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include media-breakpoint-down(md) {
      display: block;
    }

    img {
      display: inline-block;
      margin: 0 6px;
      position: relative;
      top: 0;

      @include media-breakpoint-down(md) {
        top: -2px;
      }
    }

    a {
      font-size: 12px;
      display: inline-block;
      color: $grey-color;
      font-weight: 500;

      &.active {
        color: $accent-color;
      }

      &:hover {
        color: $black-color;

        &.active {
          color: $accent-color;
        }
      }
    }
  }

}