.cookies-section {
  margin-top: 40px;

  .cookie-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 12px 20px;
    background: $white-color;
    z-index: 8;
    position: relative;
    border: 1px solid $light-color;
    @include border-radius(8px);

    @include media-breakpoint-down(md) {
      display: block;
      text-align: center;
      padding: 20px;

      a {
        display: inline-block;
      }
    }

    p {
      color: $grey-color;
      font-size: 13px;

      a {
        color: $accent-color;
        font-weight: 500;

        &:hover {
          color: $black-color;
        }
      }
    }

    .close-cookie {
      background: transparent;
      position: relative;
      border: none;
      cursor: pointer;
      width: 30px;
      height: 30px;
      background: $white-color;
      @include border-radius(2px);
      @include basic-transition;

      &:hover {
        opacity: 0.8;
      }

      @include media-breakpoint-down(md) {
        margin-top: 8px;
      }

      img {
        width: 16px;
        position: relative;
        top: 1px;
      }
    }
  }
}