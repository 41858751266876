.top-button {
  height: 45px;
  width: 48px;
  background: $accent-color;
  position: fixed;
  bottom: 200px;
  right: 0;
  outline: none !important;
  display: none;
  cursor: pointer;
  border: none;
  @include border-radius(8px);
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  @include basic-transition;

  img {
    max-width: 16px;
    display: block;
    margin: 2px auto 5px;
  }

  &:hover {
    opacity: 0.8;
  }

  @include media-breakpoint-down(md) {
    display: none !important;
  }
}

.btn-block {
  display: block;
  z-index: 20;
}