.home-realizations {
  background: #FBFBFB;

  .realizations-box {
    padding: 50px 0;

    @include media-breakpoint-down(md) {
      padding: 50px 0 0;
    }

    .realization-img {
      display: block;
      margin: 0 auto 25px;
      width: 100%;
      max-width: 420px;

      @include media-breakpoint-down(md) {
        max-width: 320px;
      }
    }

    .small-divider {
      margin: 0 0 8px;

      @include media-breakpoint-down(md) {
        margin: 0 auto 8px;
      }
    }

    h2 {
      color: $black-color;
      margin-bottom: 25px;
      font-size: 18px;

      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }
  }

  .bottom {
    margin: 0 auto;

    @include media-breakpoint-down(md) {
      margin: 40px auto 0;
    }
  }
}