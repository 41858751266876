.offers-section {

  .col-lg-3 {
    padding: 0 5px;
  }

  .offers-box {
    margin: 50px auto 0;
    background: $white-color;
    width: 100%;
    padding: 40px 30px;
    max-width: 320px;
    min-height: 315px;
    border: 1px solid $light-color;
    @include border-radius(12px);
    @include basic-transition;

    &:hover {
      @include box-shadow-custom($light-color);
    }

    @media only screen and (max-width: 1270px) {
      min-height: 380px;

      .offer-link {
        position: absolute;
        bottom: 40px;
        left: 30px;
      }
    }

    @include media-breakpoint-down(md) {
      min-height: auto;

      .offer-link {
        position: initial;
      }
    }

    .service-img {
      display: block;
      height: 54px;

      @include media-breakpoint-down(md) {
        margin: 0 auto;
      }
    }

    h3 {
      margin-top: 20px;

      @include media-breakpoint-down(md) {
        text-align: center;
      }

      a {
        color: $black-color;
        font-weight: 600;
        font-size: 18px;

        &:hover {
          color: $accent-color;
        }
      }
    }

    p {
      margin: 10px 0 30px;
      min-height: 80px;

      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }

    .offer-link {
      font-weight: 600;
      color: $accent-color;
      font-size: 14px;

      @include media-breakpoint-down(md) {
        display: table;
        margin: 0 auto;
      }

      img {
        margin-left: 4px;
        position: relative;
        top: -2px;
      }

      &:hover {
        padding-left: 15px;
      }
    }
  }
}