.advantages {
  background: #FBFBFB;

  &.home {
    background: $white-color;
  }

  .advantages-img {
    display: block;
    margin: 50px auto 0;
    width: 100%;
    max-width: 420px;
    @include border-radius(12px);

    @include media-breakpoint-down(md) {
      max-width: 320px;
    }
  }

  .advantages-content {
    margin-top: 50px;

    &.md-top {
      @include media-breakpoint-down(md) {
        margin-top: 0;
      }
    }
  }

  .flex-advantage-img {
    @include media-breakpoint-down(lg) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }

  .advantage-box {
    display: flex;
    justify-content: space-between;
    max-width: 360px;
    margin: 0 auto 40px;

    .icon {
      min-width: 36px;
      height: 34px;
      display: block;
      background: $accent-color;
      margin-right: 20px;
      position: relative;
      @include border-radius(6px);

      img {
        position: absolute;
        width: 16px;
        top: 28%;
        display: block;
        margin: 0 auto;
        left: 0;
        right: 0;
      }
    }

    h2 {
      font-size: 18px;
      color: $black-color;
      margin-top: 4px;
      margin-bottom: 20px;
    }

    p {
      color: $grey-color;
      font-size: 13px;
    }

    @include media-breakpoint-down(md) {
      display: block;

      .icon {
        width: 32px;
        min-width: 32px;
        height: 30px;
        display: table;
        margin: 0 auto 12px;

        img {
          width: 14px;
        }
      }

      h2, p { text-align: center }
    }
  }
}