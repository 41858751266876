@include media-breakpoint-down(md) {
  .wow{
    animation-name: none !important;
    visibility: visible !important;
  }
}

@mixin pulse($color1,$color2,$color3) {
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 $color1;
      -moz-box-shadow: 0 0 0 0 $color1;
      box-shadow: 0 0 0 0 $color1;
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px $color2;
      -moz-box-shadow: 0 0 0 10px $color2;
      box-shadow: 0 0 0 10px $color2;
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 $color3;
      -moz-box-shadow: 0 0 0 0 $color3;
      box-shadow: 0 0 0 0 $color3;
    }
  }

  animation: pulse 1.5s infinite;
}


.animated {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {
  0%, 20%, 40%, 60%, 80%, 100% {-webkit-transform: translateY(0);}
  50% {-webkit-transform: translateY(-10px);}
}

@keyframes bounce {
  0%, 20%, 40%, 60%, 80%, 100% {transform: translateY(0);}
  50% {transform: translateY(-10px);}
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
}

@keyframes pulse_animation {
  0% { transform: scale(1.4); }
  20% { transform: scale(1.3); }
  30% { transform: scale(1.2); }
  40% { transform: scale(1.1); }
  50% { transform: scale(1); }
  60% { transform: scale(1.1); }
  70% { transform: scale(1.2); }
  80% { transform: scale(1.3); }
  100% { transform: scale(1.4); }
}

@keyframes bar_animation {
  to {
    width: 40px;
  }
}

.pulse-animation {
  animation-name: pulse_animation;
  animation-duration: 4000ms;
  transform-origin:70% 70%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
