.partners-section {

  .partners-slider {
    margin-top: 60px;
  }

  .partner-box {
    background: $white-color;
    width: 100%;
    max-width: 220px;
    height: 160px;
    border: 1px solid $light-color;
    margin: 0 auto 4px;
    @include border-radius(8px);
    @include variable-transition(.3s);

    &:hover {
      @include box-shadow-custom($light-color);
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
    }

    .partner-img {
      max-height: 46px;
      width: auto;
      max-width: 70%;
    }
  }
}