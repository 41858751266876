.systems {
  background: #FBFBFB;

  h1 {
    margin-bottom: 50px;
  }

  &.home, &.realization-section {
    background: $white-color;
  }

  .system-content {
    display: flex;
    justify-content: space-between;
    outline: 1px solid $light-color;
    min-height: 340px;

    @include media-breakpoint-down(sm) {
      display: block;
    }

    .image-box {
      background-position: top -1px center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 50%;

      @include media-breakpoint-down(sm) {
        width: 100%;
        padding: 140px 0;
      }

      &.first {
        background-image: url('../../dist/img/images/fotowoltaika-1.jpg');
      }

      &.second {
        background-image: url('../../dist/img/images/fotowoltaika-2.jpg');
      }

      &.third {
        background-image: url('../../dist/img/images/fotowoltaika-3.jpg');
      }

      &.fourth {
        background-image: url('../../dist/img/images/oferta-4.jpg');
      }
    }

    .text-box {
      width: 50%;
      padding: 40px;

      @include media-breakpoint-down(sm) {
        width: 100%;
        padding: 40px 20px;
      }

      h2 {
        font-size: 18px;
        margin-bottom: 20px;
        color: $black-color;

        &.bottom {
          margin-top: 30px;
        }
      }
    }
  }
}