// LIBRARIES
@import '../node_modules/bootstrap/scss/bootstrap';

@import '../node_modules/animate.css/animate.min.css';
@import '../node_modules/owl.carousel/dist/assets/owl.carousel.min.css';
@import '../node_modules/owl.carousel/dist/assets/owl.theme.default.min.css';

// FONTS
@import '../scss/fonts/font.css';

// UTILS
@import 'utils/variables';
@import 'utils/mixins';
@import 'utils/helpers';
@import 'utils/animation';

// BASE
@import 'base/reset';
@import 'base/typography';

// LAYOUT
@import 'layout/top-button';
@import 'layout/header';
@import 'layout/navbar';
@import 'layout/footer';
@import 'layout/cookies';
@import 'layout/breadcrumbs';
@import 'layout/pushy';
@import 'layout/mobile-menu';

// COMPONENTS
@import 'components/buttons';
@import 'components/forms';

// PAGES - ERROR
@import 'pages/error/error';

// PAGES - HOME
@import 'pages/home/main';
@import 'pages/home/offers';
@import 'pages/home/partners';
@import 'pages/home/realizations';

// PAGES - DOCUMENTS
@import 'pages/documents/documents';

// PAGES - CONTACT
@import 'pages/contact/contact';

// PAGES - ABOUT
@import 'pages/about/about';
@import 'pages/about/advantages';
@import 'pages/about/faq';

// PAGES - OFFER
@import 'pages/offer/offer';
@import 'pages/offer/system';
@import 'pages/offer/who';

// PAGES - REALIZATION
@import 'pages/realization/realization';

// PAGES - REALIZATIONS
@import 'pages/realizations/realizations';

// PAGES - PRIVATE POLICY
@import 'pages/private-policy/private-policy';