.faq {

  h1,h2 {
    color: $black-color;
  }

  .head-box {
    text-align: center;
    margin-bottom: 80px;

    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }
  }

  h2 {
    font-size: 22px;

    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }

    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }

  .left-text {
    max-width: 420px;
    color: $grey-color;
    margin: 30px 0 50px;
    font-size: 13px;

    a {
      color: $accent-color;
      font-weight: 600;

      &:hover {
        color: $black-color;
      }
    }

    @include media-breakpoint-down(md) {
      margin: 30px auto 50px;
      text-align: center;
    }
  }

  .main-button {
    display: block;
    width: 180px;

    @include media-breakpoint-down(md) {
      margin: 0 auto 80px;
    }

    img {
      position: relative;
      top: -2px;
      margin-left: 4px;
    }
  }

  .faq-content {
    margin-top: 30px;

    @include media-breakpoint-down(md) {
      max-width: 380px;
      margin: 30px auto 0;
    }

    h4 {
      &.active {
        h3 {
          color: $accent-color;
        }
      }
    }

    h3 {
      color: $black-color;
      font-size: 16px;
      font-weight: 600;

      @include media-breakpoint-down(md) {
        text-align: center;
        font-size: 15px;
      }

      img {
        position: relative;
        top: 4px;
        margin-right: 8px;

        @include media-breakpoint-down(md) {
          display: block;
          margin: 0 auto 15px;
        }
      }
    }

    .panel-body {
      padding: 25px 0;

      p {
        font-size: 14px;
        line-height: 22px;
        max-width: 500px;
        border: 1px solid $light-color;
        padding: 15px 25px;
        margin: 0 0 0 40px;
        background: $white-color;
        color: $grey-color;
        @include box-shadow-custom($light-color);
        @include border-radius(15px);


        a,button {
          color: $accent-color;
          font-weight: 600;
          outline: none;
          background: transparent;
          border: none;

          &:hover {
            color: $black-color;
          }
        }

        @include media-breakpoint-down(md) {
          margin: 0 auto;
          text-align: center;
        }
      }
    }

    .faq-box {
      margin-bottom: 25px;
    }
  }
}