.who {

  &.home {
    background: #FBFBFB;;
  }

  .who-content {
    margin: 50px 0 0;
    display: flex;
    justify-content: space-between;
    border: 1px solid $light-color;
    min-height: 320px;
    @include border-radius(12px);

    @include media-breakpoint-down(xs) {
      display: block;
    }

    .image-box {
      background-position: top -1px center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 45%;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;

      @include media-breakpoint-down(xs) {
        width: 100%;
        padding: 100px 0;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        border-bottom-left-radius: 0;
      }

      &.first {
        background-image: url('../../dist/img/images/dla-kogo-1.jpg');
      }

      &.second {
        background-image: url('../../dist/img/images/dla-kogo-2.jpg');
      }

      &.third {
        background-image: url('../../dist/img/images/dla-kogo-3.jpg');
      }

      &.fourth {
        background-image: url('../../dist/img/images/dla-kogo-4.jpg');
      }
    }

    .text-box {
      width: 55%;
      padding: 40px 20px;

      @include media-breakpoint-down(xs) {
        width: 100%;
      }

      h2 {
        font-size: 18px;
        margin-bottom: 20px;
        color: $black-color;
      }
    }
  }
}