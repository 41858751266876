.documents-site {

  .document-box {
    margin: 50px auto 0;
    background: $white-color;
    width: 100%;
    padding: 40px 30px;
    max-width: 320px;
    border: 1px solid $light-color;
    position: relative;
    @include border-radius(12px);

    h3 {
      font-weight: 600;
      color: $black-color;

      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }

    .document-img {
      display: block;
      margin: 0 0 20px;

      @include media-breakpoint-down(md) {
        margin: 0 auto 20px;
      }
    }

    .download-btn {
      display: block;
      background: transparent;
      border: none;
      margin-top: 40px;
      font-size: 14px;
      font-weight: 600;
      color: $accent-color;

      @include media-breakpoint-down(md) {
        display: table;
        margin: 40px auto 0;
      }

      img {
        position: relative;
        top: -2px;
        margin-left: 6px;
      }

      &:hover {
        padding-left: 15px;
      }
    }
  }
}