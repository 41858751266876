.main-button {
  position: relative;
  display: block;
  width: 180px;
  height: 47px;
  outline: none;
  border: none;
  line-height: 47px;
  text-align: center;
  cursor: pointer;
  color: $white-color;
  background: $accent-color;
  font-size: 14px;

  @include border-radius(6px);
  @include left-right-hover;

  &:hover {
    color: $white-color;
  }

  img {
    position: relative;
    top: -2px;
    margin-left: 4px;
  }

  &.white {
    background: $white-color;
    color: $accent-color;
    border: 1px solid $accent-color;

    @include left-right-red-hover;
  }
}