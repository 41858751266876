footer {
  background-image: url('../../dist/img/bg/footer-bg.jpg');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0 30px;

  h3 {
    font-size: 18px;
    font-weight: 600;
    color: $black-color;

    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }

  .footer-logo {
    margin-bottom: 30px;
    height: 52px;

    @include media-breakpoint-down(md) {
      display: block;
      margin: 0 auto 30px;
    }
  }

  .desc {
    color: $grey-color;
    max-width: 420px;
    font-size: 12px;

    @include media-breakpoint-down(md) {
      text-align: center;
      margin: 0 auto 40px;
    }
  }

  .small-divider {
    margin: 0 0 8px;

    @include media-breakpoint-down(md) {
      margin: 0 auto 8px;
    }
  }

  .contact-box {
    margin-top: 30px;

    @include media-breakpoint-down(md) {
      text-align: center;
    }

    a {
      color: $grey-color;
      @include basic-transition;

      &:hover {
        color: $black-color;
      }

      &.phone {
        color: $black-color;
        font-size: 28px;
        display: block;
        font-weight: 600;
        margin-bottom: 30px;

        &:hover {
          color: $accent-color;
        }
      }
    }

    .contact-details {

      @include media-breakpoint-down(md) {
        display: table;
        margin: 0 auto;
      }

      p {
        margin: 12px 0;
        color: $grey-color;
      }

      img {
        float: left;
        margin-right: 10px;
        position: relative;
        top: 3px;

        &.second-img {
          margin-left: 4px;
          margin-right: 14px;
        }
      }

      span {
        float: left;
      }
    }
  }

  .links-box {
    margin-top: 30px;

    @include media-breakpoint-down(md) {
      display: table;
      margin: 30px auto 0;
    }

    @include media-breakpoint-down(sm) {
      margin: 30px auto;
    }

    a {
      color: $grey-color;
      display: block;
      margin: 12px 0;
      position: relative;
      left: -20px;
      font-weight: 500;
      @include variable-transition(.3s);

      @include media-breakpoint-down(md) {
        text-align: center;
        left: 0;
      }

      img {
        position: relative;
        top: -3px;
        margin-right: 4px;
        opacity: 0;
        @include variable-transition(.3s);

        @include media-breakpoint-down(md) {
          display: none;
        }
      }


      &:hover {
        left: 0;
        color: $black-color;

        img {
          opacity: 1;
        }
      }

      &.bottom {
        margin-top: 32px;
      }
    }
  }

  .copyright-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    border-top: 1px solid #D5D5D5;
    margin-top: 40px;

    @include media-breakpoint-down(sm) {
      display: block;
    }

    p {
      color: $grey-color;

      @include media-breakpoint-down(sm) {
        text-align: center;
        margin: 12px auto 0;
      }

      a {
        font-weight: 600;
        color: $accent-color;

        &:hover {
          color: $black-color;
        }
      }
    }
  }
}