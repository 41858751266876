@mixin basic-transition {
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

@mixin variable-transition($time) {
  -webkit-transition: all $time ease-in-out;
  -moz-transition: all $time ease-in-out;
  -ms-transition: all $time ease-in-out;
  transition: all $time ease-in-out;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin box-shadow-bottom($color) {
  box-shadow: 0 3px 9px -2px $color;
  -moz-box-shadow: 0 3px 9px -2px $color;
  -o-box-shadow: 0 3px 6px -2px $color;
  -webkit-box-shadow: 0 3px 6px -2px $color;
}

@mixin box-shadow-custom($color) {
  box-shadow: 0 3px 6px $color;
  -moz-box-shadow: 0 3px 6px $color;
  -o-box-shadow: 0 3px 6px $color;
  -webkit-box-shadow: 0 3px 6px $color;
}